<template>
  <Banner />
  <CloudProducts />
  <CloudPlatform />
  <Overview />
  <OtherProducts />
  <Footer />
  <CopyRight/>
</template>
<script>
import Banner from '@/components/banner.vue'
import CloudProducts from '@/components/product/cloud-products.vue'
import CloudPlatform from '@/components/product/cloud-platform.vue'
import Overview from '@/components/product/overview.vue'
import OtherProducts from '@/components/product/other-products/other-products.vue'
import Footer from '@/components/footer.vue'
import CopyRight from '@/components/copy-right.vue'

export default {
  name:'Product',
  components: {
    Banner,
    CloudProducts,
    CloudPlatform,
    Overview,
    OtherProducts,
    CopyRight,
    Footer
  }
}
</script>

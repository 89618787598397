<template>
  <div
    class="layout__title-container"
    :style="{ marginBottom: title.marginBottom, marginTop: title.marginTop }"
  >
    <div class="layout__title">{{ title.text }}</div>
    <div class="layout__sub-title">
      {{ title.subText }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {type: Object, default: () => ({})},
  },
  setup (props) {
    const {marginBottom, marginTop, text, subText} = props.title
    return {
      title: {marginBottom, marginTop, text, subText},
    }
  },
}
</script>
<style lang="scss" scoped>
.layout__title-container {
  position: relative;
}

.layout__title {
  position: relative;
  text-align: center;
  height: 45px;
  line-height: 45px;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0d253e;
  line-height: 45px;
  letter-spacing: 2px;
}

.layout__sub-title {
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  height: 22px;

  white-space: nowrap;
  font-size: 16px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #0d253e;
  line-height: 22px;
  letter-spacing: 1px;
}
</style>

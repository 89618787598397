<template>
  <HostLayoutContainer class="banner" :style="{ height: '659px' }">
    <HostLayoutItem>
      <TitleWithUnderline :title="title" id="cloud-products"/>
      <Card8Container>
        <template #top-left><Card8 :gap="gap" :img="img" :title="cardTitle.item1" :content="content.item1" /></template>
        <template #top-right ><Card8 :gap="gap" :img="img" :title="cardTitle.item2" :content="content.item2" /></template>
        <template #bottom-left><Card8 :gap="gap" :img="img" :title="cardTitle.item3" :content="content.item3" /></template>
        <template #bottom-right ><Card8 :gap="gap" :img="img" :title="cardTitle.item4" :content="content.item4" /></template>
      </Card8Container>
    </HostLayoutItem>
  </HostLayoutContainer>
</template>
<script>

import TitleWithUnderline from '@/components/title/title-with-underline.vue'
import Card8 from '@/components/cards/card8.vue'
import Card8Container from '@/components/cards/card8-container.vue'
export default {
  components: {
    TitleWithUnderline,
    Card8,
    Card8Container
  },

  setup () {
    return {
      // 标题
      title: { marginBottom: '93px', marginTop: '70px', text: '云产品' },
      // card
      gap: { marginBottom: '75px', paddingBottom: '30px', paddingRight: '30px', paddingTop: '34px', paddingLeft: '44px', logoMarginRight: '14px', ContentWidth: '373px' },
      img: { width: '68px', height: '72px', src: '/assets/home/microsoft@3x.png' },
      cardTitle: {
        item1: { fontSize: '30px', color: '#737373', lineHeight: '47px', text: 'Microsoft Azure' },
        item2: { fontSize: '30px', color: '#737373', lineHeight: '47px', text: 'Microsoft 365' },
        item3: { fontSize: '30px', color: '#737373', lineHeight: '47px', text: 'Microsoft Power Platform' },
        item4: { fontSize: '30px', color: '#737373', lineHeight: '47px', text: 'Microsoft Dynamics 365' },
      },
      content: {
        item1: { fontSize: '22px', color: '#737373', lineHeight: '32px', text: '智能云' },
        item2: { fontSize: '22px', color: '#737373', lineHeight: '32px', text: '生产力云' },
        item3: { fontSize: '22px', color: '#737373', lineHeight: '32px', text: '低代码开发云' },
        item4: { fontSize: '22px', color: '#737373', lineHeight: '32px', text: '业务云' }
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.banner {
  background-color: #fff;
}
</style>


<template>
<div>1234</div>
</template>

<script>
export default {
 name:'Product2',
 setup () { 
 return {
   }
 }
}
</script>
<style scoped>
</style>
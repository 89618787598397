<template>
  <HostLayoutContainer class="banner" :style="{ height: '872px' }">
    <HostLayoutItem>
      <TitleWithSub :title="title" id="overview"/>
      <div class="overview_img">
        <img src="/assets/product/overview@3x.png" alt="" />
      </div>
    </HostLayoutItem>
  </HostLayoutContainer>
</template>
<script>
import TitleWithSub from '@/components/title/title-with-sub.vue'

export default {
  components: {
    TitleWithSub,
  },
  setup () {
    return {
      title: { marginBottom: '80px', marginTop: '60px', text: '功能总览', subText: '帮助客户在一个地方管理所有云计算资源' },
    }
  }
}
</script>
<style lang="scss" scoped>
.banner {
  background-color: #fff;
}
.overview_img {
  width: 1000px;
  height: 579px;
  margin: auto;
}
</style>
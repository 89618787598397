<template>
  <HostLayoutContainer class="banner">
    <HostLayoutItem :style="{ paddingLeft:'23px',paddingRight:'23px',paddingTop:'110px',paddingBottom:'110px' }">
      <img src="/assets/product/otherProduct_2@3x.png" alt="">
    </HostLayoutItem>
  </HostLayoutContainer>
</template>
<style lang="scss" scoped>
.banner {
  height: 688px;
}
</style>
<template>
  <HostLayoutContainer class="banner">
    <HostLayoutItem>
      <TitleWithUnderline :title="title" id="other-products"/>
    </HostLayoutItem>
  </HostLayoutContainer>
  <ItemPicture1 />
  <ItemPicture2 />
  <ItemPicture3 />
  <ItemPicture4 />
</template>
<script>
import TitleWithUnderline from '@/components/title/title-with-underline.vue'
import ItemPicture1 from './item/item1.vue'
import ItemPicture2 from './item/item2.vue'
import ItemPicture3 from './item/item3.vue'
import ItemPicture4 from './item/item4.vue'
export default {
  components: {
    TitleWithUnderline,
    ItemPicture1,
    ItemPicture2,
    ItemPicture3,
    ItemPicture4
  },

  setup () {
    return {
      // 标题
      title: { marginBottom: '82px', text: '其他产品' },    }
  },
}
</script>
<style lang="scss" scoped>
.banner {
  background-color: #fff;
}
</style>

